import ABI from './ICO.abi.json';
import farm_abi from './Farm.abi.json';
import zedafarm_abi from './ZedaFarm.abi.json';
import weth_abi from "./WETH.abi.json"
import lp1_abi from './LP.abi.json';
export const FarmAddress = '0x65666dcd7E9afCC97f45Cc18C82Ed5ef028Cd932';
export const ZedaFarmAddress = '0xC42cc535bc730395FecE071570568A4C6799863c';
export const ICOAddress = '0x3b9bAa9e292F2Cd709699cbd547050b9Ad6e4844';
export const RouterAddress = '0x7853cDd20592aaff59D6e89B59bF67499564C525';
export const ICO_ABI = ABI;
export const LP_ABI = lp1_abi;
export const FARM_ABI = farm_abi;
export const ZEDAFARM_ABI = zedafarm_abi;
export const WZETA_ABI = weth_abi;
export const ICO_Price = 1200; // 1 Eth => 12000XLR


//torasie
