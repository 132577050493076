import React, { useState, useEffect, useRef } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import logo from "../images/zetatransparent.png";
import { CgMenuGridO } from "react-icons/cg";
import { Link } from "react-router-dom";
import { Navbar } from "../components/Navbar";
import defiIllama from "../images/deff.png";
import dexScreener from "../images/DEX-Screener.jpg";
import { TheFarm } from "../components/TheFarm";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Home = () => {
  const [isLoading, setIsLoading] = useState(true);
  const chartRef = useRef(null);
  const [isNavOpen, setIsNavOpen] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const chart = chartRef.current;
    if (!chart) return;

    const ctx = chart.ctx;
    const gradient = ctx.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, "rgba(247, 147, 26, 0.5)");
    gradient.addColorStop(1, "rgba(0, 0, 0, 0)");

    chart.data.datasets[0].backgroundColor = gradient;
    chart.update();
  }, []);

  const data = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ], // example months
    datasets: [
      {
        label: "ZEDA",
        data: [
          30000, 35000, 32000, 36000, 40000, 45000, 42000, 48000, 47000, 50000,
          53000, 55000,
        ], // example data
        fill: true,
        borderColor: "#0fa",
        pointBorderColor: "rgb(247, 147, 26)",
        pointBackgroundColor: "#fff",
        pointHoverBackgroundColor: "rgb(247, 147, 26)",
        pointHoverBorderColor: "#fff",
        tension: 0.4,
      },
    ],
  };

  const options = {
    responsive: true,
    animations: {
      tension: {
        duration: 1000,
        easing: "linear",
        from: 1,
        to: 0,
        loop: true,
      },
    },
    scales: {
      y: {
        beginAtZero: false,
      },
    },
  };

  // if (isLoading) {
  //   return (
  //     <div className="LoadingScreen bg-[#0e0c0c] h-screen w-screen flex flex-col justify-center items-center">
  //       <img src={logo} alt="Loading..." className=" duration-100 animate-pulse mb-4" />
  //       <div className="Typewriter">
  //         <h1 className='text-[#0fa] text-xl font-semibold'>Welcome to ZedaSwap.................................................</h1>
  //       </div>
  //     </div>
  //   );
  // }

  function toggleNav() {
    //console.log("clicked")
    if (isNavOpen) {
      setIsNavOpen(false);
      //console.log(isNavOpen)
    }
    if (!isNavOpen) {
      setIsNavOpen(true);
      //console.log(isNavOpen)
    }
  }

  return (
    <div className="App">
      <header className="bg-[#131010] md:px-32 z-50 px-4 fixed w-full flex justify-between items-center">
        <Link to="/">
          <div className="flex items-center">
            <img
              src={logo}
              alt=""
              height={80}
              width={80}
              className="relative"
            />
          </div>
        </Link>
        <div className="relative">
          <div
            className="text-[#0fa] text-3xl font-bold cursor-pointer hover:brightness-75 hover:scale-125 duration-300"
            onClick={() => {
              toggleNav();
            }}
          >
            <CgMenuGridO />
          </div>
          {isNavOpen && <Navbar />}
        </div>
      </header>

      <main className="md:px-32 bg-[#0e0c0c] h-screen w-screen text-gray-300 md:flex md:space-x-10 md:items-center px-8 md:text-left text-center pt-32">
        <div className="basis-1/2">
          <div className="">
            <h1 className="text-[40px] font-bold mb-[7px]">
              Explore <span className="text-[#0fa]">Zeda</span>Swap
            </h1>

            <p className="text-[20px]">
              Your Gateway to a Secure, Fast, and{" "}
              <span className="text-[#0fa] font-semibold">BORDERLESS</span>{" "}
              Decentralized Trading Experience. Proudly Built by the Zetachain
              Community, for the Community.
            </p>
          </div>
          <a href="http://app.zedaswap.xyz/#/swap">
            <button className="bg-[#0fa] px-10 py-2 text-2xl font-semibold text-[#080707] border border-[#0fa] mt-4 cursor-pointer rounded-sm hover:brightness-75 duration-300 hover:bg-transparent hover:border hover:border-[#0fa] hover:text-[#0fa]">
              Launch App
            </button>
          </a>
        </div>

        <div className="md:basis-1/2 animate-pulse w-[100%] h-fit mx-auto pt-14 md:pt-0">
          <Line ref={chartRef} data={data} options={options} />
        </div>
      </main>

      <section className="md:px-32 bg-[#0e0c0c] text-center text-white md:pb-2 pb-2">
        <h1 className="text-4xl font-semibold">Tracked By:</h1>
        <div className="flex w-fit mx-auto space-x-6 mt-6">
          <img
            src={defiIllama}
            alt=""
            className="mx-auto border border-[#0fa] rounded-lg w-[100px]"
          />
          <img
            src={dexScreener}
            alt=""
            className="mx-auto border border-[#0fa] rounded-lg w-[100px]"
          />
        </div>
      </section>


      <section className="md:px-32 bg-[#0e0c0c] text-center text-white md:pb-2 pb-2">
        <div className="mx-auto md:w-[40%]  p-8 relative">
          {/* <img src={logo} alt='' className=' mx-auto' width={500}/> */}
          <h1 className=" top-[100px] text-gray-500 text-sm">
            Powered by <span className="text-[#0fa]">ZetaChain</span> || ©
            ZedaSwap 2024
          </h1>
        </div>
      </section>
    </div>
  );
};

export default Home;
