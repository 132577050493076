import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/home";
// import Farm from "./pages/Farm";
//import Launchpad from "./pages/Launchpad";
import Farm from "./pages/Farm";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/launchpad" element={<Launchpad />} /> */}
        <Route path="/farm" element={<Farm />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
