import React from 'react';
import { Link } from "react-router-dom";
import { PiSwapBold } from "react-icons/pi";
import { FaPiggyBank } from "react-icons/fa";
import { GiFarmTractor } from "react-icons/gi";
import { HiRocketLaunch } from "react-icons/hi2";
import { HiDocumentCheck } from "react-icons/hi2";
import { SiTwitter } from "react-icons/si";
import { FaTelegram } from "react-icons/fa";

export const Navbar = () => {
  return (
    <nav className=' md:w-[300px] w-[100vw] min-h-screen md:min-h-min top-14 z-50 -right-4 bg-[rgba(0,255,170,0.7)]  rounded-sm absolute text-xl font-semibold text-[#0fa] p-5'>
      <div className='flex flex-col space-y-3'>
        <a href='http://app.zedaswap.xyz/#/swap'>
          <div className='border border-[#0fa] flex items-center justify-end  rounded-lg p-4  bg-[#131010] cursor-pointer hover:bg-[#0fa] hover:text-[#131010] hover:border-[#131010] duration-300 text-right'>Swap <span className='ml-4'><PiSwapBold /></span></div>
        </a>
        <a href='http://app.zedaswap.xyz/#/pool'>
          <div className='border border-[#0fa] flex items-center justify-end rounded-lg p-4  bg-[#131010] cursor-pointer hover:bg-[#0fa] hover:text-[#131010] hover:border-[#131010] duration-300 text-right'>Pool <span className='ml-4'><FaPiggyBank /></span></div>
        </a>

        <Link to='/farm'>
          <div className='border border-[#0fa] flex items-center justify-end rounded-lg p-2  bg-[#131010] cursor-pointer hover:bg-[#0fa] hover:text-[#131010] hover:border-[#131010] duration-300 text-right'>Farm <span className='ml-4'><GiFarmTractor /></span> </div>
        </Link>
        <Link to='/'>
          <div className='border border-[#0fa] flex items-center justify-end rounded-lg p-2  bg-[#131010] cursor-pointer hover:bg-[#0fa] hover:text-[#131010] hover:border-[#131010] duration-300 text-right'>LaunchPad <span className='ml-4'><HiRocketLaunch /></span></div>
        </Link>
        <a href='https://zedaswap.gitbook.io/zedaswap/'>
          <div className='border border-[#0fa] flex items-center justify-end rounded-lg p-2  bg-[#131010] cursor-pointer hover:bg-[#0fa] hover:text-[#131010] hover:border-[#131010] duration-300 text-right'>Documentation <span className='ml-4'><HiDocumentCheck /></span></div>
        </a>
        <a href='https://x.com/ZedaSwap?t=TDrQyuOwpyroo8Qkf4CyPg&s=09'>
          <div className='border border-[#0fa] flex items-center justify-end rounded-lg p-2  bg-[#131010] cursor-pointer hover:bg-[#0fa] hover:text-[#131010] hover:border-[#131010] duration-300 text-right'>Twitter <span className='ml-4'><SiTwitter /></span></div>
        </a>
        <a href='https://t.me/ZedaSwapPortal'>
          <div className='border border-[#0fa] flex items-center justify-end rounded-lg p-2  bg-[#131010] cursor-pointer hover:bg-[#0fa] hover:text-[#131010] hover:border-[#131010] duration-300 text-right'>Telegram <span className='ml-4'><FaTelegram /></span></div>
        </a>
      </div>
    </nav>
  )
}
